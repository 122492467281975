<template>
  <main>
    <content-page-header>
      <common-page-title title="404 NOT FOUND" />
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <section>
          <common-block-title
            title="お探しのページが見つかりませんでした。"
            sub-title=""
          />
          <div class="notfound-message">
            <p class="notfound-message-text">
              申し訳ございませんが、お探しのページが見つかりませんでした。<br />
              お探しのページは削除されたか、URLが変更された可能性がございます。
            </p>
          </div>
        </section>
        <div class="submit-area">
          <button
            class="submit-area-button submit-area-button--save"
            @click="$router.push('/')"
          >
            TOPページへ
          </button>
        </div>
      </panel-body>
    </div>
  </main>
</template>

<script>
import { defineComponent, reactive } from "vue";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
export default defineComponent({
  name: "notfound",

  components: {
    contentPageHeader,
    CommonPageTitle,
    PanelBody,
    CommonBlockTitle,
  },
  props: [],
  setup() {
    const state = reactive({});
    return {
      state,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.content-header) {
  height: auto;
  padding: 100px 100px 200px;
  background-size: cover;
  @include mq(sm) {
    padding: 100px 20px 200px;
  }
}
.content-body {
  position: relative;
  margin-top: -137px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.notfound-message {
  padding: 65px 0;
  border-bottom: 0.5px solid #0f0f11;
}
.notfound-message-text {
  line-height: 1.7;
}
.submit-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 65px;
  @include mq(md) {
    display: block;
  }
  .submit-area-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    padding: 1.5em;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    @include mq(md) {
      width: 100%;
    }
    &--save {
      border: 1px solid $black;
      background-color: $black;
      color: #ffffff;
    }
  }
}
</style>